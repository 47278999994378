<template>

    <router-view/>


</template>

<script>
    export default {
        name: 'Wrapper',

    }
</script>
